.resume-wrap {
  display: flex;
  flex-direction: column;
  margin-left: 15rem;
  margin-right: 15rem;

  @media (max-width: 1200px) {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  @media (max-width: 992px) {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  @media (max-width: 768px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @media (max-width: 576px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.basic-info-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-main {
  display: flex;
  gap: 3rem;
  justify-content: center;
  margin-bottom: 1rem;
}

.social-main > a {
  color: black;
  display: flex;
  justify-content: center;
}

.section-title-main {
  align-content: center;
  color: #7a808e;
  display: flex;
  font-size: 2.5rem;
  font-weight: bold;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 1.5rem;

  @media (max-width: 576px) {
    font-size: 2rem;
  }
}

.buttons-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0.5rem;
}

.tags-button {
  align-self: center;
  background-color: #fff;
  background-position: 0 90%;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-style: solid;
  border-width: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 15px 28px 25px -18px;
  box-sizing: border-box;
  color: #41403e;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  line-height: 2px;
  margin: 0.3rem;
  outline: none;
  padding: 0.75rem;
  text-decoration: none;
  touch-action: manipulation;
  transition: all 235ms ease-in-out;
  user-select: none;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 8px -5px;
    transform: translate3d(0, 2px, 0);
  }

  &:focus {
    box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 4px -6px;
  }

  &--selected {
    background-color: black;
    color: white;
  }
}

.company-info-wrap {
  display: flex;
  flex-direction: column;
}

.section-title {
  color: #7a808e;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
  margin-top: 2rem;

  @media (max-width: 576px) {
    font-size: 1.5rem;
  }
}

.company-section {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.company-name-and-dates {
  color: #8894a8;
  display: flex;
  font-weight: bold;
}

.company-dates {
  display: flex;
  flex-direction: row-reverse;
}

.company-examples {
  margin-bottom: 2.5rem;
}

.education-info-wrap > .section-title {
  margin-top: 0;
}

.education-info {
  color: #8894a8;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 5rem;
  }
}

.education-name {
  font-weight: bold;
}

.education-dates {
  font-style: italic;
}
