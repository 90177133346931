.container {
    background-color: black;
    border: 1px solid #fff;
    height: 100vh;
    margin: 0;
}

.center-xy {
    color: white;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.index-module_type__E-SaG::after {
    background: #ffe500;
    content: '' !important;
    height: 33px;
    margin-top: 3px;
    width: 14px;
}

.link-to-go-back {
    left: 50%;
    position: absolute;
    top: 60%;
}

.link-to-go-back > a {
    color: white;
}
