.landing-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 1rem;
    box-sizing: border-box;
}

.pic-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    width: 100%;
    padding-top: 2rem; /* Default padding for smaller screens */
    box-sizing: border-box;
}

img {
    border: dotted 3px #7a808e;
    border-radius: 15px;
    box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    height: auto;
    width: 100%;
    max-width: 20rem;
    object-fit: contain;
    transition: all 0.13s ease-in;
}

img:hover {
    box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
    transform: translateY(-10px) rotate(1deg);
}

.info-wrapper {
    max-width: 40rem;
    text-align: center;
    margin: 0 1rem;
}

.text {
    font-family: 'Yanone Kaffeesatz', serif;
    font-size: 5rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.wrapper {
    padding: 0 0.5rem;
}

.letter {
    transform: translateY(40%);
}

.shadow {
    color: #999;
    transform: scale(1, -1);
}

.about-me {
    margin-bottom: 2rem;
}

.buttons-wrap-resume {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tags-button {
    margin: 0.5rem 0;
}

@media (min-width: 720px) {
    .landing-wrapper {
        flex-direction: row;
        padding: 2rem;
    }

    .pic-wrapper {
        margin-right: 2rem;
        margin-bottom: 0;
        padding-top: 0; /* Reset padding for larger screens */
    }

    .info-wrapper {
        text-align: left;
        margin-top: 0;
    }

    .text {
        justify-content: flex-start;
    }
}

@media (max-width: 719px) {
    .text {
        font-size: 2rem;
    }

    .wrapper {
        padding: 0 0.2rem;
    }

    .about-me, .buttons-wrap-resume {
        padding: 0 1rem;
    }
}

@media (max-width: 768px) {
    .pic-wrapper {
        padding-top: 3rem; /* Increased padding for smaller screens */
    }
}

@media (max-width: 425px) { /* Additional media query for smaller devices like iPhone 12 Pro */
    .pic-wrapper {
        padding-top: 4rem; /* Increased padding for smaller screens */
    }

    img {
        max-width: 15rem; /* Ensure image scales down appropriately */
    }
}

@media (max-width: 360px) { /* Specific adjustments for very small devices like Galaxy S8 */
    .pic-wrapper {
        padding-top: 5rem; /* Further increased padding for very small screens */
    }

    img {
        max-width: 12rem; /* Ensure image scales down appropriately */
    }
}
