.fab-container {
    border: 3px double black;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    flex-direction: column-reverse;
    left: 2em;
    list-style: none;
    margin: 0;
    max-height: 52px;
    padding: 0.2rem;
    position: fixed;
    top: 2em;

    &.open {
        max-height: max-content;
    }

    li {
        border-radius: 50%;
        box-shadow: 0 3px 6px lightgrey;
        cursor: pointer;
        display: grid;
        font-size: 28px;
        margin: 8px 0;
        padding: 12px;
        place-items: center;
        position: relative;
    }
}

.fab-container:hover {
    background-color: black;
    box-shadow: 0 0 0 3px #7a808e;
    color: white;
}
