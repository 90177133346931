.App {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
    height: 100vh;
    margin: 0;
}

body {
    $bg-color: #fff;
    $dot-color: #7a808e;

    // Dimensions
    $dot-size: 1.3px;
    $dot-space: 22px;

    background: linear-gradient(
                90deg,
                $bg-color ($dot-space - $dot-size),
                transparent 1%
            )
            center,
            linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%)
                center,
            $dot-color;
    background-size: $dot-space $dot-space;
}
